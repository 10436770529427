exports.components = {
  "component---src-components-templates-post-html-redirect-tsx": () => import("./../../../src/components/templates/PostHtmlRedirect.tsx" /* webpackChunkName: "component---src-components-templates-post-html-redirect-tsx" */),
  "component---src-components-templates-post-list-of-tag-tsx": () => import("./../../../src/components/templates/PostListOfTag.tsx" /* webpackChunkName: "component---src-components-templates-post-list-of-tag-tsx" */),
  "component---src-components-templates-post-tsx": () => import("./../../../src/components/templates/Post.tsx" /* webpackChunkName: "component---src-components-templates-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-works-mdx": () => import("./../../../src/pages/works.mdx" /* webpackChunkName: "component---src-pages-works-mdx" */)
}

